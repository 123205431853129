import {
  Box,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import NavbarAdmin from "./NavbarAdmin";
import { ReactSession } from 'react-client-session';
import { useDetectAdBlock } from "adblock-detect-react";

const drawerWidth = 250;

export default function AdminHome() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  const [userInfo,setUserInfo] = useState(null);
  ReactSession.setStoreType("localStorage");

  const sessionData = ReactSession.get("username");
  if(ReactSession.get("username")){
   if(userInfo !== null ){
    setUserInfo(sessionData);

   }else{
   }
  }else if(sessionData === ''){
    window.open("/admin","_self");
  }else{
    window.open("/admin","_self");
  }

  return (
    <Box sx={{ display: "flex" }}>
      <NavbarAdmin/>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Box>
           Welcome, What do you want to do today chose from side Menu
        </Box>
      </Box>
    </Box>
  );
}
