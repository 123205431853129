import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";
import { createTheme } from "@mui/system";
import { grey } from "@mui/material/colors";
import axios from "axios";
import CryptoJS from "crypto-js";
import { ReactSession } from 'react-client-session';
import { useDetectAdBlock } from "adblock-detect-react";
function Index() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  ReactSession.setStoreType("localStorage");
  localStorage.clear();
    const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "1px solid grey",
    boxShadow: 20,
    borderRadius: "25px",
  };
  const textFiledStyle = {
    backgroundColor: "#f0f4fa",
    borderRadius: "3px",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        "border-color": "grey",
      },
    },
    " & label.Mui-focused ": {
      color: "black",
    },
  };

  const buttonStyle = {
    backgroundColor: "white",
    border: "1px solid grey",
    color: "black",
    ":hover": {
      backgroundColor: "white",
      color: "black",
      border: "1px solid grey",
    },
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    window.open("/admin","_self")
  };

  let formInfo = {
    email: "",
    password: "",
  };
  const [formData, setFormData] = useState(formInfo);

  function handleInput(event) {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  const onSubmitLogin = async(e) => {
    let encryptedData="";
    await axios.post('https://api.ancientrom.xyz/api/users/login',formData).then((response)=> response)
    .then((res)=>{
      if(res.status === 200){
        const decryptData = CryptoJS.AES.decrypt(res.data.result.password , "AnkitHacker01@RezaIDC")
        const hannnnkOCK = JSON.parse(decryptData.toString(CryptoJS.enc.Utf8));
        if(hannnnkOCK === formData.password){
          let userData = res.data.result
          ReactSession.set("username", userData);
          window.open("/adminHome","_self");
        }else{
          // console.log("Password Dose not match")
        }
      }else{  
        // console.log("Something Went wrong")
      }
    })
  }


  let signUpInfo = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    githubUsername:"",
    password: "",
    confirmPassword: "",
  };
  const [signUpData, setSignUpdata] = useState(signUpInfo);
  const [errrorSignup, setErrorSignup] = useState("");

  function handleInputSignup(event) {
    setSignUpdata({ ...signUpData, [event.target.name]: event.target.value });
  }

  const handleSignUpSubmit = async () => {
    if (signUpData.password === signUpData.confirmPassword) {
      signUpData.password = CryptoJS.AES.encrypt(
        JSON.stringify(signUpData.password),
        "AnkitHacker01@RezaIDC"
      ).toString();
      signUpData.confirmPassword = CryptoJS.AES.encrypt(
        JSON.stringify(signUpData.confirmPassword),
        "AnkitHacker01@RezaIDC"
      ).toString();
      await axios.post('https://api.ancientrom.xyz/api/users/signup',signUpData)
      .then((response)=> response)
      .then((res)=>{
        if(res.data.statusCode === 200){
          handleClose()
        }else{
          setErrorSignup("Enter the data correctly")
        }
      })
    }
  };
  return (
    <div>
      <div className="mx-4 my-8 md:my-4 xl:mx-20 border-2 shadow-inner rounded-xl p-4 lg:p-2 ">
        <div>
          <p className="flex md:text-2xl lg:text-4xl font-serif justify-center text-center items-center mt-4 mb-4">
            Welcome To Login Section Of AncientOS
          </p>
        </div>
        <div>
          <form className="lg:p-8 grid sm:w-20 md:w-1/3 lg:w-1/4 gap-3 mx-auto">
            <TextField
              id="outlined-basic"
              label="email"
              name="email"
              variant="outlined"
              onChange={handleInput}
              sx={{ ...textFiledStyle }}
            />
            <TextField
              id="outlined-basic"
              label="password"
              name="password"
              type="password"
              variant="outlined"
              onChange={handleInput}
              sx={{ ...textFiledStyle }}
            />
            <Button
              variant="contained"
              xs={8}
              sx={{ ...buttonStyle }}
              endIcon={<SendIcon />}
              onClick={(e)=>onSubmitLogin(e)}
            >
              Login
            </Button>
            <Button
              variant="contained"
              xs={8}
              sx={{ ...buttonStyle }}
              endIcon={<SendIcon />}
              onClick={handleOpen}
            >
              Signup
            </Button>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{ ...style }}>
                <div className=" mx-4 my-4 border-2 shadow-inner rounded-xl p-4 lg:p-2 ">
                  <div className="float-right">
                    <ClearIcon
                      sx={{ border: "2px solid grey" }}
                      onClick={handleClose}
                    />
                  </div>
                  <div>
                    <div>
                      <p className="flex md:text-2xl lg:text-4xl font-serif justify-center text-center items-center mt-4 mb-4">
                        Signup
                      </p>
                    </div>
                    <div>
                      <form className="lg:p-8 grid sm:w-20 md:w-1/3 lg:w-1/4 gap-3 mx-auto">
                        <TextField
                          id="outlined-basic"
                          label="First Name"
                          name="firstName"
                          variant="outlined"
                          onChange={handleInputSignup}
                          sx={{ ...textFiledStyle }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Last Name"
                          name="lastName"
                          variant="outlined"
                          onChange={handleInputSignup}
                          sx={{ ...textFiledStyle }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="userName"
                          name="userName"
                          variant="outlined"
                          onChange={handleInputSignup}
                          sx={{ ...textFiledStyle }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Email"
                          name="email"
                          variant="outlined"
                          onChange={handleInputSignup}
                          sx={{ ...textFiledStyle }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Github UserName"
                          name="githubUsername"
                          variant="outlined"
                          onChange={handleInputSignup}
                          sx={{ ...textFiledStyle }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Password"
                          type="password"
                          name="password"
                          variant="outlined"
                          onChange={handleInputSignup}
                          sx={{ ...textFiledStyle }}
                        />
                        <TextField
                          id="outlined-basic"
                          type="password"
                          label="Confirm Password"
                          name="confirmPassword"
                          variant="outlined"
                          onChange={handleInputSignup}
                          sx={{ ...textFiledStyle }}
                        />
                        {errrorSignup ? (
                          <div className="text-center text-red-500">
                            {errrorSignup}
                          </div>
                        ) : (
                          ""
                        )}
                        <Button
                          variant="contained"
                          xs={8}
                          sx={{ ...buttonStyle }}
                          endIcon={<SendIcon />}
                          onClick={handleSignUpSubmit}
                        >
                          Signup
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Index;
