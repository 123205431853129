import {
  Button,
  Modal,
  NativeSelect,
  Table,
  TableCell,
  TableRow,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import NavbarAdmin from "../NavbarAdmin";
import { editorConfig } from "./Joedit";
import { useState } from "react";
import JoditEditor from "jodit-react";
import { ButtonStyle } from "./Style";
import { ReactSession } from 'react-client-session';
import { useDetectAdBlock } from "adblock-detect-react";

const drawerWidth = 250;

export default function UpdateMangement() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  ReactSession.setStoreType("localStorage");

  const sessionData = ReactSession.get("username");


  if(ReactSession.get("username")){

    let role = sessionData.isAdmin;

  }else if(sessionData === ''){
    window.open("/admin","_self");
  }else{
    window.open("/admin","_self");
  }
  const [data, setData] = useState();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    background: "white",
    border: "1px solid grey",
    boxShadow: 20,
    borderRadius: "25px",
  };
  const textFiledStyle = {
    backgroundColor: "#f0f4fa",
    borderRadius: "3px",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        "border-color": "grey",
      },
    },
    " & label.Mui-focused ": {
      color: "black",
    },
  };

  const buttonStyle = {
    backgroundColor: "white",
    border: "1px solid grey",
    color: "black",
    ":hover": {
      backgroundColor: "white",
      color: "black",
      border: "1px solid grey",
    },
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <NavbarAdmin />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Box>
          <div className="float-right">
            <Button
              variant="outlined"
              sx={{ border: "1px solid grey", color: "grey" }}
              onClick={handleOpen}
            >
              Add New
            </Button>
          </div>
          <Table>
            <TableRow>
              <TableCell>Sr No</TableCell>
              <TableCell>Rom Version</TableCell>
              <TableCell>Android Version</TableCell>
              <TableCell>Manage</TableCell>
              <TableCell>Display To Public</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>6.4</TableCell>
              <TableCell>Android 12L</TableCell>
              <TableCell>
                <Button
                  sx={{ ...ButtonStyle }}
                  variant="outlined"
                  onClick={handleOpen}
                >
                  Mange Changelog
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  sx={{ ...ButtonStyle }}
                  variant="outlined"
                  onClick={handleOpen}
                >
                 Yes
                </Button>
              </TableCell>
            </TableRow>
          </Table>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...style }}>
              <div className=" mx-4 my-4 border-2 shadow-inner rounded-xl p-4 lg:p-2 ">
                <div className="float-right">
                  <ClearIcon
                    sx={{ border: "2px solid grey" }}
                    onClick={handleClose}
                  />
                </div>
                <div className="grid md:grid-cols-2 lg:grid-cols-5">
                  <div>Rom Version Name :- </div>
                  <div>
                    <input
                      className="border-2"
                      id="outlined-basic"
                      label="Outlined"
                      variant="outlined"
                    />
                  </div>
                  <div>Android Version</div>
                  <div>
                    <NativeSelect sx={{ maxWidth: "150px" }}>
                      <option>Hello</option>
                      <option>HELlo</option>
                    </NativeSelect>
                  </div>
                  <div>
                    <Button>Release Now</Button>
                  </div>
                </div>
                <JoditEditor
                  value={data}
                  config={editorConfig}
                  onChange={(value) => setData(value)}
                />
              </div>
              <div className="flex flex-col items-center">
                <Button sx={{ ...ButtonStyle }} variant="outlined">
                  Save Changes
                </Button>
              </div>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
}
