import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import romLogo from "./../../Assests/Images/ancientv2.png";
import { Await, Link } from "react-router-dom";

import axios from "axios";
import { useDetectAdBlock } from "adblock-detect-react";

function Nav() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  const [isOpen, setIsOpen] = useState(false);
  const [menuData, setMenuData] = useState();

  const fetchData = async() => {
      return await axios.get("https://api.ancientrom.xyz/api/menu")
        .then(response=>response.data)
        .then(response=>{setMenuData(response)})
  };

  useEffect(() => {
    fetchData();
 }, []);
  return (
    <div>
      <nav className="bg-white backdrop-blur-sm border-2 shadow-xl fixed top-0 w-full z-50 overflow-hidden">
      {/* <nav className="bg-transparent backdrop-blur-sm border-2 shadow-xl fixed top-0 w-full z-50 overflow-hidden"> */}
        <div className="max-w-7xl  mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Link key="Home" to="/">
                  <img className="h-12 w-12" src={romLogo} alt="Workflow" />
                </Link>
              </div>
              <div className="hidden md:block absolute right-0">
                <div className="mr-20 flex items-baseline space-x-4">
                  {
                    menuData?.map((userObj, index) =>
                      userObj.display === true ? (
                        userObj.navOptionLink !== window.location.pathname ? (
                          <Link
                            key={userObj.id}
                            to={userObj.navOptionLink}
                            className="text-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          >
                            {userObj.navOption}
                          </Link>
                        ) : null
                      ) : null
                    )}
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {menuData.map((userObj, index) =>
                      userObj.display === true ? (
                        userObj.navOptionLink !== window.location.pathname ? (
                          <Link
                            key={userObj.id}
                            to={userObj.navOptionLink}
                            className="text-black hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                          >
                            {userObj.navOption}
                          </Link>
                        ) : null
                      ) : null
                    )}
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
}

export default Nav;
