import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  NativeSelect,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link, redirect, useParams } from "react-router-dom";
import { ButtonStyle } from "./Style";
import JoditEditor from "jodit-react";
import { useState } from "react";
import { editorConfig } from "./Joedit";
import axios from "axios";
import NavbarAdmin from "../NavbarAdmin";
import { ReactSession } from 'react-client-session';
import { useDetectAdBlock } from "adblock-detect-react";

const drawerWidth = 250;

function BuildInfoModal() {
  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  const adBlockDetected = useDetectAdBlock();
  ReactSession.setStoreType("localStorage");

  const sessionData = ReactSession.get("username");
  
  if(ReactSession.get("username")){
    let role = sessionData.isAdmin;
  }else if(sessionData === ''){
    window.open("/admin","_self");
  }else{
    window.open("/admin","_self");
  }

  const { id } = useParams();
  const [notes, setNotes] = useState();
  const [deviceChangelog, setDeviceChangelog] = useState();
  const [buildTypeSupported, setBuildTypeSupported] = useState();
  const [romVersionSupported, setRomVersionSupported] = useState();
  const [romVersions, setRomVersions] = useState();
  const [androidVersions, setAndroidVersions] = useState();
  const [androidVersion, setAndroidVersion] = useState();
  const [romVersion, setRomVersion] = useState();

  let finalData = {
    buildGappsType: buildTypeSupported,
    device: { id: id },
    // id: 0,
    supportedAndroidVersion: {id:androidVersions},
    notes: notes,
    releaseChangelogs: deviceChangelog,
    releaseDate: new Date().toLocaleString(),
    romBuildVersion: romVersions,
    romBuildVersionType: romVersionSupported
  
  };
  const saveBuild = async()=>{

    return await axios.post("https://api.ancientrom.xyz/api/builds",finalData)
    .then((response)=>response).then((res)=>{
      // console.log("deviceCheck",res)
      if(res.status && res.data === "Saved Build"){
        window.open("/deviceMangement","_self");
      }else{

        } }
    )
  }
  const getAndroidVersion = async () => {
    let data;
    return await axios
      .get("https://api.ancientrom.xyz/api/AndroidVersion")
      .then((response) => response.data)
      .then((res) => ((data = res), setAndroidVersion([...data])));
  };

  const getRomVersion = async () => {
    let data;
    return await axios
      .get("https://api.ancientrom.xyz/api/romversion")
      .then((response) => response.data.result)
      .then((res) => ((data = res), setRomVersion([...data])));
  };

  useEffect(() => {
    getAndroidVersion();
    getRomVersion();
 }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <NavbarAdmin />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Box>
          <div>
            <Accordion defaultExpanded={true} sx={{ pointerEvents: "none" }}>
              <AccordionSummary>
                <Typography>
                  <p className="font-serif font-bold text-xl">
                    Fill Build Information 
                  </p>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* BASIC SUMMARY */}
                <Accordion
                  defaultExpanded={true}
                  sx={{
                    pointerEvents: "auto",
                    boxShadow: "inset 0 0 4px #c7c5c5",
                    borderRadius: "16px",
                  }}
                >
                  <AccordionSummary>
                    <Typography>
                      <p className="font-serif font-bold text-xl">
                        Basic Information
                      </p>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex">
                      <div className="w-[75%]">
                        {/* <div>Maintainer : </div>
                  <div>Co Maintainer Name : </div> */}
                        {/* <div>Device Maintain Status : Maintained</div> */}
                        <div>
                          Supported Android Versions :
                          <NativeSelect
                            name="supportRomVersion"
                            onChange={(e) => setAndroidVersions(e.target.value)}
                            sx={{ maxWidth: "150px" }}
                          >
                            <option value={""}></option>
                            {androidVersion &&
                              androidVersion?.map((data, index) => (
                                <option value={data.id}>
                                  {data.androidVersion}
                                </option>
                              ))}
                          </NativeSelect>
                        </div>
                        <div>
                          Supported Rom Version :
                          <NativeSelect
                            name="supportRomVersion"
                            onChange={(e) => setRomVersions(e.target.value)}
                            sx={{ maxWidth: "150px" }}
                          >
                          
                            {romVersion &&
                              romVersion?.map((data, index) => (
                                <option value={data.id}>
                                  {data.romVersion}
                                </option>
                              ))}
                          </NativeSelect>
                        </div>
                      </div>
                      <div className="w-[25%] ">
                        <img className="w-[50%] mt-[-20%]" src="" alt="" />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                {/* Rom Support Channel */}
                {/*<Accordion
            defaultExpanded={true}
            sx={{
              pointerEvents: "auto",
              boxShadow: "inset 0 0 4px #c7c5c5",
              borderRadius: "16px",
            }}
          >
             <AccordionSummary>
              <Typography>
                <p className="font-serif font-bold text-xl">
                  Rom Support Channel
                </p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                Rom Support Channel :
                <Link className="text-black italic">AncientOS Update</Link>
              </div>
              <div>
                Rom Support Group :
                <Link className="text-black italic">Ancient.id</Link>
              </div>
              <div>
                Device Support Group :
                <Link className="text-black italic">Click To Visit</Link>
              </div>
            </AccordionDetails>
          </Accordion> */}

                {/* Device Changelog */}
                <Accordion
                  defaultExpanded={true}
                  sx={{
                    pointerEvents: "auto",
                    boxShadow: "inset 0 0 4px #c7c5c5",
                    borderRadius: "16px",
                  }}
                >
                  <AccordionSummary>
                    <Typography>
                      <p className="font-serif font-bold text-xl">
                        Device Changelog
                      </p>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <JoditEditor
                      value={deviceChangelog}
                      config={editorConfig}
                      onChange={(value) => setDeviceChangelog(value)}
                    />
                    <Typography>
                      <p className="font-serif font-bold text-xl">Notes:- </p>
                    </Typography>
                    <JoditEditor
                      value={notes}
                      config={editorConfig}
                      onChange={(value) => setNotes(value)}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* Downloads */}
                <Accordion
                  defaultExpanded={true}
                  sx={{
                    pointerEvents: "auto",
                    boxShadow: "inset 0 0 4px #c7c5c5",
                    borderRadius: "16px",
                  }}
                >
                  <AccordionSummary>
                    <Typography>
                      <p className="font-serif font-bold text-xl">
                        Downloads:-
                      </p>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <p className="font-serif font-bold ">
                        Supported Rom Version :
                        <NativeSelect
                          name="supportRomVersion"
                          onChange={(e) =>
                            setRomVersionSupported(e.target.value)
                          }
                          sx={{ maxWidth: "150px" }}
                        ><option value={""}></option>
                         <option value={"TexasCakse"}>TexasCake</option>
                          <option value={"Society"}>Society</option>
                          <option value={"Weeabo"}>Weeabo</option>
                          <option value={"Society/Weeabo"}>
                            Society/Weeabo
                          </option>
                          <option value={"TexasCake/Weeabo"}>
                          TexasCake/Weeabo
                          </option>
                        </NativeSelect>
                      </p>
                    </Typography>
                    <Typography>
                      <p className="font-serif font-bold ">
                        Build Types Supported :
                        <NativeSelect
                          onChange={(e) =>
                            setBuildTypeSupported(e.target.value)
                          }
                          sx={{ maxWidth: "150px" }}
                        >
                          <option value={""}></option>
                          <option value={"Gapps"}>Gapps</option>
                          <option value={"Vanilla"}>Vanilla</option>
                          <option value={"Gapps/Vanilla"}>Gapps/Vanilla</option>
                        </NativeSelect>
                      </p>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            <div className="flex flex-col items-center">
                  <Button sx={{ ...ButtonStyle }} variant="outlined" onClick={saveBuild}>
                    Save Changes
                  </Button>
                </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default BuildInfoModal;
