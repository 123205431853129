import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import NavbarAdmin from "../NavbarAdmin";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Style,
  TextFiledStyle,
  ButtonStyle,
  StyleHeight,
  StyleHeightAuto,
} from "./Style";
import DeviceInfoModal from "./DeviceInfoModal";
import BuildInfoModal from "./BuildInfoModal";
import { Link } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { useDetectAdBlock } from "adblock-detect-react";

const drawerWidth = 250;

export default function DeviceMangement() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  ReactSession.setStoreType("localStorage");

  const sessionData = ReactSession.get("username");

  if(ReactSession.get("username")){
    let role = sessionData.isAdmin;
    console.log("sessionData",sessionData)
  }else if(sessionData === ''){
    window.open("/admin","_self");
  }else{
    window.open("/admin","_self");
  }

  const [open, setOpen] = React.useState(false);
  const [openInternal, setOpenInternal] = React.useState(false);
  const [openManageBuilds, setOpenManageBuilds] = React.useState(false);
  const [openInternalBuild, setOpenInternalBuild] = React.useState(false);
  const [deviceID,setDeviceID] = useState();
  const handleOpen = (value) => {
    setDeviceID(value)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenInternalBuild = (value) => {
    setDeviceID(value)
    handleCloseManageBuilds();
    setOpenInternalBuild(true);
  };
  const handleCloseInternalBuild = () => {
    handleOpenManageBuilds();
    setOpenInternalBuild(false);
  };
  const handleOpenInternal = () => {
    setOpenInternal(true);
  };
  const handleCloseInternal = () => {
    setOpenInternal(false);
  };
  const handleOpenManageBuilds = (value) => {
    getBuilds(value);
    setDeviceID(value)
    handleClose();
    setOpenManageBuilds(true);
  };
  const handleCloseManageBuilds = () => {
    setOpenManageBuilds(false);
    handleOpen();
  };

  const [deviceTemp, setDeviceTemp] = useState();

  const getDeviceTempInfo = async () => {
    return await axios
      .get("https://api.ancientrom.xyz/api/deviceAdminControl")
      .then((response) => response.data)
      .then((response) => {
        let temp = response;
        if(typeof(temp) !== 'string'){
          setDeviceTemp([...temp]);
        }
      });
  };

  const [buildsInfo, setBuildInfo] = useState();
  const getBuilds = async (id) => {
    let data;
    await axios
      .get(`https://api.ancientrom.xyz/api/buildsList/${id}`)
      .then((respone) => respone.data)
      .then((res) => ((data = res), setBuildInfo([...data])));
  };

  useEffect(() => {
    // getBuilds(deviceID);
    getDeviceTempInfo();
 }, []);

  const updateDeviceStatus = async (id) => {
    return await axios
      .get(`https://api.ancientrom.xyz/api/deviceIdForDisplay/${id}`)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          return(
            window.open("/deviceMangement","_self")
          );
        }
      });
  };

  const handleRelaseDevice = async (id) => {
    return await axios
      .get(`https://api.ancientrom.xyz/api/releaseDevice/${id}`)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          return(
            window.open("/deviceMangement","_self")
          );
        }
      });
  };
  const updateDeviceMaintainedStatus = async (id) => {
    return await axios
      .get(`https://api.ancientrom.xyz/api/deviceIdForMaintained/${id}`)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          return(
            window.open("/deviceMangement","_self")
          );

        }
      });
  };

  const releaseBuildSingle = async (deviceID,buildId) => {
    console.log("DeviceIDBuildId",deviceID,buildId)
    await axios
    .get(`https://api.ancientrom.xyz/api/releaseBuild/${buildId}/${deviceID}`)
    .then((respone) => respone.data)
    .then((res) => {
      if(res.statusCode === 200){
        return(
          window.open("/deviceMangement","_self")
        );
      }
    });
  }

  return (
    <Box sx={{ display: "flex" }}>
      <NavbarAdmin />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Box>
          <div className="float-right">
            <Button
              sx={{ ...ButtonStyle }}
              variant="outlined"
              onClick={handleOpenInternal}
            >
              Add New Device
            </Button>
          </div>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Sr No</TableCell>
                <TableCell>Devices</TableCell>
                <TableCell>Maintainers UserName</TableCell>
                <TableCell>Co-Maintainers UserName</TableCell>
                { sessionData && sessionData.isAdmin ?  <TableCell>display</TableCell> : null}
                <TableCell>Handle Builds</TableCell>
                { sessionData && sessionData.isAdmin ? <TableCell>Device Status</TableCell> : null}
              </TableRow>
            </TableBody>
            {deviceTemp &&
              deviceTemp.map((device, index) => (
                sessionData && sessionData.isAdmin ? (
                  <TableRow>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{device.device}</TableCell>
                  <TableCell>{device.maintainerusername}</TableCell>
                  <TableCell>{device.comaintainerusername}</TableCell>
                  { sessionData && sessionData.isAdmin ? 
                  <TableCell>
                    {device.display ? (
                      <Button
                        sx={{ ...ButtonStyle }}
                        variant="outlined"
                        onClick={(e) => updateDeviceStatus(device.id)}
                      >
                        No
                      </Button>
                    ) : (
                      <Button
                        sx={{ ...ButtonStyle }}
                        variant="outlined"
                        onClick={(e) => updateDeviceStatus(device.id)}
                      >
                        Yes
                      </Button>
                    )}
                  </TableCell> : null }
                  <TableCell>
                    <Button
                      sx={{ ...ButtonStyle }}
                      variant="outlined"
                      onClick={(e)=>handleOpen(e.target.value)}
                      value={device.id}
                    >
                      Click Here
                    </Button>
                  </TableCell>
                  { sessionData && sessionData.isAdmin ?
                  <TableCell>
                    {device.maintained ? (
                      <Button
                        sx={{ ...ButtonStyle }}
                        variant="outlined"
                        onClick={(e) => updateDeviceMaintainedStatus(device.id)}
                      >
                        UnOfficial
                      </Button>
                    ) : (
                      <Button
                        sx={{ ...ButtonStyle }}
                        variant="outlined"
                        onClick={(e) => updateDeviceMaintainedStatus(device.id)}
                      >
                        Official
                      </Button>
                    )}
                  </TableCell>
                  : null}
                </TableRow>
                )  : ((sessionData && sessionData.userName === device.maintainerusername || sessionData.userName === device.comaintainerusername  ? 
                  <TableRow>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{device.device}</TableCell>
                  <TableCell>{device.maintainerusername}</TableCell>
                  <TableCell>{device.comaintainerusername}</TableCell>
                  { sessionData && sessionData.isAdmin ? 
                  <TableCell>
                    {device.display ? (
                      <Button
                        sx={{ ...ButtonStyle }}
                        variant="outlined"
                        onClick={(e) => updateDeviceStatus(device.id)}
                      >
                        No
                      </Button>
                    ) : (
                      <Button
                        sx={{ ...ButtonStyle }}
                        variant="outlined"
                        onClick={(e) => updateDeviceStatus(device.id)}
                      >
                        Yes
                      </Button>
                    )}
                  </TableCell> : null }
                  <TableCell>
                    <Button
                      sx={{ ...ButtonStyle }}
                      variant="outlined"
                      onClick={(e)=>handleOpen(e.target.value)}
                      value={device.id}
                    >
                      Click Here
                    </Button>
                  </TableCell>
                  { sessionData && sessionData.isAdmin ?
                  <TableCell>
                    {device.maintained ? (
                      <Button
                        sx={{ ...ButtonStyle }}
                        variant="outlined"
                        onClick={(e) => updateDeviceMaintainedStatus(device.id)}
                      >
                        UnOfficial
                      </Button>
                    ) : (
                      <Button
                        sx={{ ...ButtonStyle }}
                        variant="outlined"
                        onClick={(e) => updateDeviceMaintainedStatus(device.id)}
                      >
                        Official
                      </Button>
                    )}
                  </TableCell>
                  : null}
                </TableRow>
                : null))
                 
              ))}
          </Table>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...Style }}>
          <div className=" mx-4 my-4 border-2 shadow-inner rounded-xl p-4 lg:p-2 ">
            <div className="float-right">
              <ClearIcon
                sx={{ border: "2px solid grey" }}
                onClick={handleClose}
              />
            </div>
            <div>
              <div className="lg:p-8 grid md:grid-cols-2 lg:grid-cols-2 sm:w-20 md:w-1/2 lg:w-1/2 gap-2 mx-auto">
              { sessionData && sessionData.isAdmin ?
                <Button variant="contained" xs={8} sx={{ ...ButtonStyle }} onClick={(e)=>handleRelaseDevice(deviceID)}>
                  Release Device
                </Button> : null}
                {/* { sessionData && sessionData.isAdmin ?
                <Button variant="contained" xs={8} sx={{ ...ButtonStyle }}>
                  Release Update
                </Button> : null} */}
                <Button
                  variant="contained"
                  xs={8}
                  sx={{ ...ButtonStyle }}
                  onClick={(e)=>handleOpenManageBuilds(e.target.value)}
                  value={deviceID}
                >
                  Manage Build
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openManageBuilds}
        onClose={handleCloseManageBuilds}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...StyleHeightAuto }}>
          <div className=" mx-1 my-1 border-2 shadow-inner rounded-xl p-2 lg:p-2 ">
            <div className="float-right ">
              <ClearIcon
                sx={{ border: "2px solid grey" }}
                onClick={handleCloseManageBuilds}
              />
            </div>
            <div className="p-4">
              <div className="float-right mt-4 mr-4">
                <Link to={`/BuildInfoAdd/${deviceID}`} ><Button
                  variant="contained"
                  xs={8}
                  sx={{ ...ButtonStyle }}
                  // onClick={(e)=>handleOpenInternalBuild(e.target.value)}
                  // value={deviceID}
                  // onClick={handleOpenInternalBuild}
                >
                  Add New
                </Button>
                </Link>
              </div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Sr No</TableCell>
                    <TableCell>Build Version </TableCell>
                    <TableCell>Build Release Date</TableCell>
                    {/* <TableCell>Modify Build Details</TableCell> */}
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableBody>
                {buildsInfo &&
                  buildsInfo?.map((build,index) => (
                    <TableRow>
                      <TableCell>{index+1}</TableCell>
                      <TableCell>{build.romBuildVersion}</TableCell>
                      <TableCell>{build.releaseDate}</TableCell>
                      {/* <TableCell>
                        <Button
                          variant="contained"
                          xs={8}
                          sx={{ ...ButtonStyle }}
                          onClick={handleOpenInternalBuild}
                        >
                          Edit
                        </Button>
                      </TableCell> */}
                      <TableCell>
                      { build.display ? 
                         "Build Already Released" : (
                          sessionData && sessionData.isAdmin ?
                         <Button
                         variant="contained"
                         xs={8}
                         sx={{ ...ButtonStyle }}
                         value={deviceID}
                         onClick={(e)=>releaseBuildSingle(deviceID,build.id)}
                       >
                         Release
                       </Button>  : "Ask Admin To Release"
                      )
                    }
                    </TableCell>
                    </TableRow>
                  ))}
              </Table>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openInternal}
        onClose={handleCloseInternal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...StyleHeight }}>
          <div className=" mx-1 my-1 border-2 shadow-inner rounded-xl p-2 lg:p-2 ">
            <div className="float-right ">
              <ClearIcon
                sx={{ border: "2px solid grey" }}
                onClick={handleCloseInternal}
              />
            </div>
            <div>
              <div className="mx-auto mt-8">
                <DeviceInfoModal />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* <Modal
        open={openInternalBuild}
        onClose={handleCloseInternalBuild}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...StyleHeight }}>
          <div className=" mx-1 my-1 border-2 shadow-inner rounded-xl p-2 lg:p-2 ">
            <div className="float-right ">
              <ClearIcon
                sx={{ border: "2px solid grey" }}
                onClick={handleCloseInternalBuild}
              />
            </div>
            <div>
              <div className="mx-auto mt-8">
                <BuildInfoModal deviceId={deviceID} />
              </div>
            </div>
          </div>
        </Box>
      </Modal> */}
    </Box>
  );
}
