import { useDetectAdBlock } from "adblock-detect-react";
import { root } from "postcss";
import React from "react";
import { Link } from "react-router-dom";
import romLogo from "./../../Assests/Images/ancientv2.png";

export default function Footer() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  return (
    <div className="mt-10 border-t-[1px] border-slate-300">
      <div className="md:mx-4 xl:mx-20 mb-8">
        <div className="h-4"></div>
        <div className="flex justify-center items-center ">
          <img className="w-1/12" src={romLogo}></img>
          <div className="text-lg text-center font-semibold md:text-sm">
            Ancient OS{" "}
            <span>
              <br />
              Crafted With Love
            </span>
          </div>
        </div>
          <div className="text-center ">Copyright ©2022-23 </div>
        <div className="grid md:grid-cols-2 mt-20">
          <div className="font-serif">
            <div className="font-bold">ContactUS</div>
            <div>Email: ancientteam@ancientrom.xyz</div>
          </div>
          <div className="flex gap-4 flex-row-reverse ">
            <div><Link to="">Telegram</Link></div>
            <div><Link to="">Github</Link></div>
            <div><Link to="">Twitter</Link></div>
          </div>
        </div>
      </div>
    </div>
  );
}
