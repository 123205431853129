import { useDetectAdBlock } from "adblock-detect-react";
import React from "react"
export function ScreenShotDisplay(props){
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
    return(
        <div  className="md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:mt-8 ">
             {props.screenshotDataList &&
            props.screenshotDataList.map((screenshotData) => (
              <div className="w-60 bg-white border-2 rounded-xl drop-shadow-xl mt-8  mb-4 hover:w-60 hover:border-4 hover:drop-shadow-2xl hover:shadow-zinc-700">
                <img
                  src={screenshotData.screenshotUrl}
                  className="border-2 border-slate-200 mx-auto w-[90%] mt-2 mb-2"
                  alt=""
                />
                <div className="text-xl text-center font-serif border-l italic mx-auto">
                  {screenshotData.screenshotAndoidVersion}
                </div>
              </div>
            ))}
        </div>
    )
}