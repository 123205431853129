import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  NativeSelect,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ButtonStyle, TextFiledStyle } from "./Style";
import JoditEditor from "jodit-react";
import { useState } from "react";
import { editorConfig } from "./Joedit";
import axios from "axios";
import { useEffect } from "react";
import { useDetectAdBlock } from "adblock-detect-react";

function DeviceInfoModal() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  
  const [flashingProccess, setFlashingProccess] = useState();
  const [deviceBrand, setDeviceBrand] = useState();
  const [deviceBrands, setDeviceBrands] = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);

  let dataObj = {
    id: "",
    deviceModel: "",
    deviceCodeName: "",
    flashingProccess: "",
    deviceSupportGroup: "",
    deviceImageUrl:""
  };
  const [finalData, setFinalData] = useState(dataObj);

  function handleInputData(event) {
    setFinalData({ ...finalData, [event.target.name]: event.target.value });
  }

  const setDeviceBrand1 = (id) => {
    finalData.id = id;
  };

  const setMaintainerName = (id) => {
    finalData.maintainerUserName = id;
  };

  const setCoMaintainerName = (id) => {
    finalData.coMaintainerUserName = id;
  };
  

  const getData = async() => {
    await axios
      .get("https://api.ancientrom.xyz/api/DeviceBrands")
      .then((response) => {
        setDeviceBrands(response.data);
      });
  };
  const getUserInfo = async() => {
    await axios
      .get("https://api.ancientrom.xyz/api/users")
      .then((response) => {
        setUsersList(response.data);
      });
  };
  useEffect(() => {
    getUserInfo()
    getData();
 }, []);

  const saveDeviceData = async (event) => {
    
    let finalObj = {
      brand: {
        id: finalData?.id ? finalData?.id : null,
      },
      deviceCodeName: finalData.deviceCodeName,
      deviceImageUrl: "string",
      deviceModel: finalData.deviceModel,
      deviceSupportGroup: finalData.deviceSupportGroup,
      downloadLink: "https://www.pling.com/p/1606752",
      flashingInstruction: flashingProccess,
      officialRomGroup: "https://t.me/ancientid",
      romSupportChannel: "https://t.me/ancientrom",
      deviceImageUrl: finalData.deviceImageUrl,
      maintainerUserName: {
        id:finalData.maintainerUserName
      },
      coMaintainerUserName:finalData.coMaintainerUserName ? {id: finalData.coMaintainerUserName} : null
    };
    await axios
      .post("https://api.ancientrom.xyz/api/Devices", finalObj)
      .then((response) => response)
      .then((res) => {
        if (res.data.statusCode === 200) {
          window.open("/deviceMangement","_self");

        } else {
        }
      });
  };
  return (
    <div>
      <form>
        <Accordion defaultExpanded={true} sx={{ pointerEvents: "none" }}>
          <AccordionSummary>
            <Typography>
              <p className="font-serif font-bold text-xl">Device Information</p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* BASIC SUMMARY */}
            <Accordion
              defaultExpanded={true}
              sx={{
                pointerEvents: "auto",
                boxShadow: "inset 0 0 4px #c7c5c5",
                borderRadius: "16px",
              }}
            >
              <AccordionSummary>
                <Typography>
                  <p className="font-serif font-bold text-xl">
                    Basic Information
                  </p>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex">
                  <div className="w-[75%]">
                    <div className="gap-3">
                      Device Name :
                      <NativeSelect
                        onChange={(e) => setDeviceBrand1(e.target.value)}
                        sx={{ maxWidth: "150px" }}
                      >
                        {deviceBrands &&
                          deviceBrands.map((device, index) => (
                            <option value={device.id}>
                              {device.brandName}
                            </option>
                          ))}
                      </NativeSelect>
                      <TextField
                        id="outlined-basic"
                        label="Model"
                        name="deviceModel"
                        variant="outlined"
                        size="small"
                        onChange={handleInputData}
                        sx={{ ...TextFiledStyle, marginLeft: "8px" }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="CodeName"
                        name="deviceCodeName"
                        variant="outlined"
                        onChange={handleInputData}
                        size="small"
                        sx={{ ...TextFiledStyle, marginLeft: "8px" }}
                      />
                      <br />
                      <br />
                      Device Support Group (Telegram group link) :-
                      <TextField
                        id="outlined-basic"
                        label="Device Support Group Link"
                        name="deviceSupportGroup"
                        variant="outlined"
                        onChange={handleInputData}
                        size="small"
                        sx={{ ...TextFiledStyle, marginLeft: "8px" }}
                      />
                      <br />
                      <br />
                      Device Image URl :-
                      <TextField
                        id="outlined-basic"
                        label="Device Image URL"
                        name="deviceImageUrl"
                        variant="outlined"
                        onChange={handleInputData}
                        size="small"
                        sx={{ ...TextFiledStyle, marginLeft: "8px" }}
                      />
                      <div>
                        Maintainer UserName:-
                        <NativeSelect
                        onChange={(e) => setMaintainerName(e.target.value)}
                        sx={{ maxWidth: "150px" }}
                      >
                        <option value={null}></option>

                        {usersList &&
                          usersList.map((user, index) => (
                            <option value={user.id}>
                              {user.userName}
                            </option>
                          ))}
                      </NativeSelect>
                      </div>
                      <div>
                        Co-Maintainer UserName:-
                        <NativeSelect
                        onChange={(e) => setCoMaintainerName(e.target.value)}
                        sx={{ maxWidth: "150px" }}
                      >
                        <option value={null}></option>
                        {usersList &&
                          usersList.map((user, index) => (
                            <option value={user.id}>
                              {user.userName}
                            </option>
                          ))}
                      </NativeSelect>
                      </div>
                    </div>
                  </div>
                  <div className="w-[25%] ">
                    <img className="w-[50%] mt-[-20%]" src="" alt="" />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            {/* Flashing Proccess */}
            <Accordion
              defaultExpanded={true}
              sx={{
                pointerEvents: "auto",
                boxShadow: "inset 0 0 4px #c7c5c5",
                borderRadius: "16px",
              }}
            >
              <AccordionSummary>
                <Typography>
                  <p className="font-serif font-bold text-xl">
                    Flashing Proccess
                  </p>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <JoditEditor
                  value={flashingProccess}
                  config={editorConfig}
                  onChange={(value) => setFlashingProccess(value)}
                />
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
        <div className="flex flex-col items-center">
          <Button
            sx={{ ...ButtonStyle }}
            variant="outlined"
            onClick={saveDeviceData}
          >
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  );
}

export default DeviceInfoModal;
