import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import Download from "./pages/Download";
import ScreenShots from "./pages/ScreenShots";
import Update from "./pages/Update";
import Team from "./pages/Components/Team";
import Index from './Admin/Index';
import AdminHome from './Admin/AdminHome';
import UpdateMangement from './Admin/Components/UpdateMangement';
import DeviceMangement from './Admin/Components/DeviceMangement';
import UserManagement from './Admin/Components/UserManagement';
import DeviceBrandMangement from './Admin/Components/DeviceBrandMangement';
import ScreenShotManagement from './Admin/Components/ScreenShotManagement';
import BuildInfoModal from './Admin/Components/BuildInfoModal';
import Donation from './pages/Donation';
import ApplicationManagement from './Admin/Components/ApplicationManagement';
import Applications from './pages/Applications';
import AntiAdBlockPage from './pages/AntiAdBlockPage';

function App() {
  return <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home />} />
      {/* <Route path='/download' element={<Download />} /> */}
      <Route path='/team' element={<Team />} />
      <Route path='/update' element={<Update />} />
      <Route path='/screenshots' element={<ScreenShots />} />
      <Route path='/download' element={<Download />} />
      <Route path='/donation' element={<Donation />} />
      <Route path='/applications' element={<Applications />} />
      <Route path='/admin' element={<Index />} />
      <Route path='/adminHome' element={<AdminHome />} />
      <Route path='/updateMangement' element={<UpdateMangement />} />
      <Route path='/deviceMangement' element={<DeviceMangement />} />
      <Route path='/userManagement' element={<UserManagement />} />
      <Route path='/applicationManagement' element={<ApplicationManagement />} />
      <Route path='/deviceBrandMangement' element={<DeviceBrandMangement />} />
      <Route path='/screenShotManagement' element={<ScreenShotManagement />} />
      <Route path='/BuildInfoAdd/:id' element={<BuildInfoModal />} />
      <Route path='/AntiAdBlockPage' element={<AntiAdBlockPage />} />
    </Routes>
  </BrowserRouter>
}

export default App;
