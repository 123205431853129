import { useDetectAdBlock } from 'adblock-detect-react';
import React, { useEffect, useState } from 'react'
import AncientLogo from './../../Assests/Images/ancientv2.png'
function RomLogoIcon() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  return (
        <div className='w-8 bg-transparent' ><img src={AncientLogo}  ></img></div>
    )
}

export default RomLogoIcon