import React from 'react'
import Footer from './Components/Footer'
import LandingPage from './Components/LandingPage'
import Logo from './Components/logo'
import Nav from './Components/Nav'
import Team from './Components/Team'
import AdSense from 'react-adsense';
import AdsComponent from '../AdsComponent'
import { useDetectAdBlock } from "adblock-detect-react";
export default function Home() {

  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
  }, [adBlockDetected]);

  return (
    <div >
      <div className='NavBar'>
        <Nav />
      </div>
      <div>
        <Logo />
      </div>
      <div className='md:mx-4 xl:mx-20'>
        <LandingPage />
      </div>
      <div className='md:mx-4 xl:mx-20'>
        <Team />
      </div>
      <div className=''>
        <Footer />
      </div>
    </div>
  )
}
