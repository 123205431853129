export const Style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "1px solid grey",
    boxShadow: 20,
    borderRadius: "25px",
  };

  export const StyleHeight = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
    '@media (max-width: 1024px)': {
        height: "auto",
      },
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "1px solid grey",
    boxShadow: 20,
    // borderRadius: "25px",
    "& .modal-dialog":{
        "overflow-y": "initial !important"
    }
  };
    export const StyleHeightAuto = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "auto",
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "1px solid grey",
    boxShadow: 20,
    borderRadius: "18px",
    "& .modal-dialog":{
        "overflow-y": "initial !important"
    }
  };

export const TextFiledStyle = {
    backgroundColor:'#f0f4fa',
    borderRadius:'3px',
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        "border-color": "grey",
      },
    },
    " & label.Mui-focused ": {
      color: "black",
    },
  };
export const ButtonStyle = {
    backgroundColor: "white",
    border: "1px solid grey",
    color: "black",
    ":hover": {
      backgroundColor: "white",
      color: "black",
      border: "1px solid grey",
    },
  };
