import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import NavbarAdmin from "../NavbarAdmin";
import axios from "axios";
import { Style, TextFiledStyle, ButtonStyle } from "./Style";
import { ReactSession } from 'react-client-session';
import { useDetectAdBlock } from "adblock-detect-react";

const drawerWidth = 250;

export default function UserManagement() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  ReactSession.setStoreType("localStorage");

  const sessionData = ReactSession.get("username");

  if(ReactSession.get("username")){

    let role = sessionData.isAdmin;

  }else if(sessionData === ''){
    window.open("/admin","_self");
  }else{
    window.open("/admin","_self");
  }
  const [users, setUsers] = useState();

  const getUsers = async () => {
    return await axios
      .get("https://api.ancientrom.xyz/api/users")
      .then((response) => response.data)
      .then((response) => {
        let res = response;
        setUsers([...res]);
      });
  };
  useEffect(() => {
    getUsers();
 }, []);

  const isAdminUpdate = async(id) =>{
    return await axios.get(`https://api.ancientrom.xyz/api/users/users/isAdmin/${id}`).then((res)=>{
      if(res.status){
        window.open("/userManagement","_self");
      }else{

      }
    })
  }

  const isMaintainerUpdate = async(id) =>{
    return await axios.get(`https://api.ancientrom.xyz/api/users/users/isMaintainer/${id}`).then((res)=>{
      if(res.status){
        window.open("/userManagement","_self");
      }else{

      }
    })
  }

  const isApplicantUpdate = async(id) =>{
    return await axios.get(`https://api.ancientrom.xyz/api/users/users/isApplicant/${id}`).then((res)=>{
      if(res.status){
        window.open("/userManagement","_self");
      }else{

      }
    })
  }

  return (
    <Box sx={{ display: "flex" }}>
      <NavbarAdmin />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Sr No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>User UserName</TableCell>
                <TableCell>Github UserName</TableCell>
                <TableCell>User Email</TableCell>
                <TableCell>Applicant</TableCell>
                <TableCell>Maintainer</TableCell>
                <TableCell>Admin</TableCell>
              </TableRow>
            </TableBody>
  
            {users &&
              users.map((user, index) => (
                <TableRow>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {user.firstName} {user.lastName}
                  </TableCell>
                  <TableCell>{user.userName}</TableCell>
                  <TableCell>{user.githubUsername}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.isApplicant ? (
                      <Button sx={{ ...ButtonStyle }} onClick={(e)=>isApplicantUpdate(user.id)} variant="outlined">Demote to user</Button>
                    ) : (
                      <Button sx={{ ...ButtonStyle }} onClick={(e)=>isApplicantUpdate(user.id)} variant="outlined">Promtoe to Applicant</Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {user.isMaintainer ? (
                      <Button sx={{ ...ButtonStyle }} onClick={(e)=>isMaintainerUpdate(user.id)}  variant="outlined">Demote to Applicant</Button>
                    ) : (
                      <Button sx={{ ...ButtonStyle }} onClick={(e)=>isMaintainerUpdate(user.id)} variant="outlined">Promote to Maintainer</Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {user.isAdmin ? (
                      <Button sx={{ ...ButtonStyle }} onClick={(e)=>isAdminUpdate(user.id)} variant="outlined">Demote to Maintainer</Button>
                    ) : (
                      <Button sx={{ ...ButtonStyle }} onClick={(e)=>isAdminUpdate(user.id)} variant="outlined">Promote to Admin</Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </Box>
      </Box>
    </Box>
  );
}
