import { useDetectAdBlock } from 'adblock-detect-react';
import React from 'react'
import Nav from './Components/Nav'


export default  function Applications() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  return (
    <div>
     <div className="NavBar">
        <Nav />
      </div>
      <div className="">
        {window.location.pathname === "/applications" ? (
          <div className="mt-24"></div>
        ) : null}
        <div className='h-screen'>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfjU7trqmdKuw5QdXepoKOhiiMS6zoB5k21rY3OI0xpljobEQ/viewform?embedded=true" 
        width="100%" className='h-screen'
        >Loading…</iframe>
        </div>
      </div>
    </div>
  )
}
