import React from "react";
import paypal from "./../Assests/Images/paypal.png"
import Nav from "./Components/Nav";
import saweria from "./../Assests/Images/saweria.png"
import upilogo from "./../Assests/Images/Upi.png"
import { useDetectAdBlock } from "adblock-detect-react";


export default function Donation() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  return(
    <div>
     <div className="NavBar">
        <Nav />
      </div>
      <div className="md:mx-4 xl:mx-20">
        {window.location.pathname === "/donation" ? (
          <div className="mt-24"></div>
        ) : null}
        <div>
          <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-3">
            <div className="border-2 flex items-center justify-center mt-2 lg:mt-0 text-lg uppercase shadow-sm rounded-md hover:shadow-lg">
              <a href="https://www.paypal.com/paypalme/rezaadipangestu"><img src={paypal} /></a>
            </div>
            <div className="border-2 flex items-center justify-center mt-2 lg:mt-0 text-lg uppercase shadow-sm rounded-md hover:shadow-lg">
            <a href="https://saweria.co/IDONTCARE"><img src={saweria} /></a>
            </div>
            {/* <div className="border-2 flex items-center justify-center mt-2 lg:mt-0 text-lg uppercase shadow-sm rounded-md hover:shadow-lg">
              <img src={upilogo} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  ); 
}