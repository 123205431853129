import { useDetectAdBlock } from "adblock-detect-react";
import React from "react";

function DownloadBrandList(props) {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  return (
    <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-3">
      { props.brandData.map((data, index) => (
          <div
            className="h-28 border-2 flex items-center justify-center mt-2 lg:mt-0 text-lg uppercase shadow-sm rounded-md hover:shadow-lg"
            onClick={(event) => props.required(data)}
          >
            {data.brandName}
          </div>
        ))}
    </div>
  );
}

export default DownloadBrandList;
