import { useDetectAdBlock } from "adblock-detect-react";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import AdsComponent from "../AdsComponent";
import Nav from "./Components/Nav";
import { ScreenShotDisplay } from "./ScreenShotDisplay";

function ScreenShots() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  const [androidVersion, setAndroidVersion] = useState();
  const [screenshotsData, setScreenshotsData] = useState();
  const [showScreenShots,setShowScreenSHots] = useState();
  const fetchScreenShotData = async () => {
    await axios.get("https://api.ancientrom.xyz/api/screenshotsService").then((res) => {

      setScreenshotsData(res.data);
    });
  };
  const fetchAndroidVersion = async () => {
    await axios.get("https://api.ancientrom.xyz/api/AndroidVersion").then((res) => (
  
      setAndroidVersion(res.data)
    ))
  }
  useEffect(() => {
    fetchAndroidVersion();
    // fetchScreenShotData();
 }, []);

  const handleCallSS = async(event) => {
 
    await axios.get(`https://api.ancientrom.xyz/api/screenShortListAndroidVersionWise/${event}`)
    .then((response)=>{
      if(response.data){
        setShowScreenSHots(<ScreenShotDisplay screenshotDataList={response.data} /> )
      }
    }) 
  }


  return (
    <div>
      <div className="NavBar">
        <Nav />
      </div>
      {window.location.pathname == "/screenshots" ? (
        <div className="mt-24"></div>
      ) : null}
      <div className="ml-12 lg:ml-16">
        <div className="flex text-5xl font-serif justify-center capitalize mx-4 my-4 items-center">
          ScreenShots
        </div>
        <div className="mx-4 my-4"><AdsComponent dataAdSlot='8778623707' /></div>
        <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-3 md:mt-8 ">
          {androidVersion && androidVersion.map((data, index) => (
            <div
              className="h-28 border-2 flex items-center justify-center mt-2 lg:mt-0 text-lg uppercase shadow-sm rounded-md hover:shadow-lg"
              onClick={(event) => handleCallSS(data.androidVersion)}
            >
              {data.androidVersion}
            </div>
          ))}
        </div>
        <div>
          {showScreenShots}
        </div>
      </div>
    </div>
  );
}

export default ScreenShots;
