import React from "react";
import Nav from "./Components/Nav";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import RomLogoIcon from "./Components/RomLogoIcon";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useDetectAdBlock } from "adblock-detect-react";

function Update() {

  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);

  const [changelogsData, setChangelogsData] = useState();

  const fetchData = () => {
    axios.get("https://api.ancientrom.xyz/api/SourceChangelogs")
    .then((res) => {
      res.data.forEach((element) => {
        element.changelog = element.changelog.split("~");
      });
      setChangelogsData(res.data);
    });
  };

  useEffect(() => {
    fetchData();
 }, []);

  return (
    <div>
      <div className="NavBar">
        <Nav />
      </div>
      <div className="md:mx-4 xl:mx-20">
        {window.location.pathname === "/update" ? (
          <div className="mt-24"></div>
        ) : null}
        <div>
          {changelogsData &&
            changelogsData.map((data, index) =>
              data.display === true ? (
                <div>
                  {index !== '0' ? (<div className="mt-2"></div>) : null}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<RomLogoIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        Ancient Version {data.buildVersion} 
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography >
                        Android Version : {data.buildAndroidVersion}
                      </Typography>
                      {/* <Typography>Secutriy Version : </Typography> */}
                      <Typography>
                        <ul>
                          {data.changelog.map((changelogItems, index) => (
                            <li>{changelogItems}</li>
                          ))}
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ) : null
            )}
        </div>
      </div>
    </div>
  );
}

export default Update;
