import React, { useState } from 'react'
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import gears from './../../Assests/fontawesome/gears-solid.svg'
import AdsComponent from '../../AdsComponent';
import { useDetectAdBlock } from 'adblock-detect-react';
export default function LandingPage() {

  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);

  const defaultImageSource = 'https://raw.githubusercontent.com/keneankit01/screenshots/main/Android12/lockscreen.jpg';
  const [imagesSource,setImageSource] = useState(defaultImageSource);
  function ChangeImages(){
    let val = imagesSource;
	  let val2 = 'https://raw.githubusercontent.com/keneankit01/screenshots/main/Android12/8.png';
	  let val3 = 'https://raw.githubusercontent.com/keneankit01/screenshots/main/Android12/unlockscreen.jpg';
    if(val === 'https://raw.githubusercontent.com/keneankit01/screenshots/main/Android12/lockscreen.jpg'){
      setImageSource(val3)
	  } else if(val === val3){
      setImageSource(val2)
	  } else {
      setImageSource(defaultImageSource)
	  }

  }
  return (
    <div>
         <AdsComponent dataAdSlot='8778623707' />
      <div className='flex text-4xl font-serif justify-center capitalize items-center '>Features</div>
      <div className='md:grid md:grid-cols-2 gap-12 mx-4 md:mx-8 mt-12 items-center'>
       
          <div><button  className='mx-12 w-64 lg:w-1/3 lg:mx-36 xl:mx-60 bg-white border-4 shadow-lg rounded-2xl shadow-gray-600'  onClick={ChangeImages}>
            <img  src={imagesSource} alt="PhoneImage" className='rounded-xl'></img>
          </button>
          </div>

        <div className=' bg-white border-2 rounded-xl drop-shadow-xl mt-8 '>
          <div className='flex justify-center mx-12 mt-8 font-bold text-2xl mb-4'> <img src={gears} className='w-16 justify-center'/><h1 className='mt-4'>Let's Do Some UI Modifications...</h1></div>
        <p className='text-justify mx-4 md:mx-8 font-semibold justify-center  mb-8'>Over Period Of Time We Have Noticed That People Love A Differnt Experience Sepically If They Could Personalised The Ui Of Thier Phone As Per The Taste,So We Are Trying To Best In Class Customisation And Make Your Experience Best On Your Phone</p>
        </div>

      </div>
      <div className='grid md:grid-cols-2 mx-4 md:mx-8 gap-12 mt-8 mb-12'>
        <div className='w-auto bg-white border-2 rounded-xl drop-shadow-xl  mt-4'>
          <h1 className='text-center mt-8 font-bold text-2xl'>Built For Stock & Personalised Experience</h1>
        <p className='text-justify ml-8 mr-8 mt-8 font-semibold justify-center  mb-8'>Over Period Of Time We Have Noticed That People Love A Differnt Experience Sepically If They Could Personalised The Ui Of Thier Phone As Per The Taste,So We Are Trying To Best In Class Customisation And Make Your Experience Best On Your Phone</p>
        </div>
        <div className='w-auto bg-white border-2 rounded-xl drop-shadow-xl mt-4'>
        <h1 className='text-center mt-8 font-bold text-2xl'>Ready For Daily Usage</h1>
        <p className='text-justify ml-8 mr-8 mt-8 font-semibold justify-center  mb-8'>Every Update Is Proccessed Through Some Multiple Stages Of Testing Before It Released,So That Onces Its Released Every User Could Enjoy Their Phone Fully As Per Their Choice.</p>
        </div>

        <div className='w-auto bg-white border-2 rounded-xl drop-shadow-xl  mt-4'>
          <h1 className='text-center mt-8 font-bold text-2xl'>Monthly Updates</h1>
        <p className='text-justify ml-8 mr-8 mt-8 font-semibold justify-center  mb-8'>Keep Your Mobile Up-To-Date, Safely And Quickly Upgrade To The Latest Software Available For Your Phone, And Enjoy Enhancements Like New Features, Improved Functionality And Also Fixed Bugs.</p>
        </div>
        <div className='w-auto bg-white border-2 rounded-xl drop-shadow-xl mt-4'>
        <h1 className='text-center mt-8 font-bold text-2xl'>Features</h1>
        <p className='text-justify ml-8 mx-4 md:mx-8 font-semibold justify-center  mb-8'>TThe “Monet” Theme Engine Is Where The Magic Happens When It Comes To Material You, And It’s The Algorithm That Decides What Colors Are Selected From A Wallpaper. When A User Changes Their Wallpaper On AncientOS, The Image Is Analyzed To Select A Color And Algorithmically Choose Primary, Secondary Colors Using An Initial Seed Color.</p>
        </div>
      </div>
      <AdsComponent dataAdSlot='8778623707' />
    </div>
  )
}