import { ElevatorSharp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useDetectAdBlock } from "adblock-detect-react";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import AdsComponent from "../AdsComponent";
import Nav from "./Components/Nav";
import RomLogoIcon from "./Components/RomLogoIcon";
import DownloadBrandList from "./DownloadBrandList";
import DownloadDeviceInfo from "./DownloadDeviceInfo";
import DownloadDeviceList from "./DownloadDeviceList";

function Download() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  const [devices, setDevices] = React.useState([]);
  const [showDeviceInfo,setShowDeviceInfo] = React.useState(false);
  const [deviceBrands, setDeviceBrands] = React.useState([]);
  const getData = async () => {
    await axios.get("https://api.ancientrom.xyz/api/DeviceBrands").then((response) => {
      setDeviceBrands(response.data);
    });
  };

  const handleCall = async(event) => {
    // console.log("SelectedBrand",)
    let id = event.id
    await axios.get(`https://api.ancientrom.xyz/api/Devices/${id}`)
    .then((response)=>{
      if(response.data){
        // console.log("dataVer",response.data)
        setShowDeviceInfo(<DownloadDeviceList deviceData={response.data} brandData={deviceBrands} devices={devices}/> )
      }
    }) 
  }

  const getAllDevices = async(event) => {
    // console.log(event)
    await axios.get(`https://api.ancientrom.xyz/api/Devices`)
    .then((response)=>(
      console.log("dataDevice",response.data),
      setDevices(response.data)
    ))
  }

  useEffect(() => {
    getData();
    getAllDevices();
    // console.log(deviceBrands);
 }, []);

  return (
    <div>
      {window.location.pathname === "/download" ? (
        <div className="mt-24"></div>
      ) : null}
      <div className="NavBar">
        <Nav />
      </div>
      {/* <AdsComponent dataAdSlot='8778623707' /> */}
      <div className="md:mx-4 xl:mx-20 border-2  shadow-inner roteunded-xl p-2">
        {/* <div className="flex gap-2">
          <div className="w-[20%] border-2">

          </div>
          <div className="w-[80%] mx-2 mt-2 font-serif"></div>
        </div> */}
          <div className=" my-4 mx-4 md:mx-8 font-serif"> 
            { showDeviceInfo? showDeviceInfo : <DownloadBrandList brandData={deviceBrands} required={handleCall} />}
          </div>
        <div className=" my-4 mx-8 md:mx-8 ">
        <AdsComponent dataAdSlot='8778623707' />
        </div>
      </div>
    </div>
  );
}

export default Download;
