import { useDetectAdBlock } from "adblock-detect-react";
import axios, { Axios } from "axios";
import React, { useEffect, useState } from "react";
import AdsComponent from "../../AdsComponent";
import Nav from "./Nav";

export default function Team() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  const [teamMemberData, setTeamMemberData] = useState();
  const fetchTeamMemberData = async() => {
    return axios.get("https://api.ancientrom.xyz/api/TeamMember").then((res) => {
      setTeamMemberData(res.data);
    });
  };
  useEffect(() => {
    fetchTeamMemberData();
 }, []);
  return (
    <div>
      {
        window.location.pathname === "/team" ? (
          <div className="NavBar">
            <Nav />
          </div>
        ):null
      }
      {window.location.pathname === "/team" ? (
        <div className="mt-24"></div>
      ) : null}
      <div className="mx-12 lg:mx-20 xl:mx-10">
        <div className="flex text-5xl font-serif justify-center capitalize ml-4 mt-4 items-center">
          Meet Our Team
        </div>
        <div className={ window.location.pathname === "/team" ? "grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  mx-auto sm:ml-6 md:ml-20 lg:ml-10" : "grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  mx-auto sm:ml-6"} >
          {teamMemberData &&
            teamMemberData.map((teamData) => (
              <div className="w-[98%] md:w-60 bg-white border-2 rounded-xl shadow-inner mt-8  mb-4 md:hover:w-60 hover:drop-shadow-2xl">
                <img
                  src={teamData.imageUrl}
                  className="rounded-full border-2 border-slate-200 mx-auto w-[90%] mt-2 mb-2"
                  alt=""
                />
                <div className="text-xl text-center font-serif border-l italic mx-auto">
                  {teamData.name}
                </div>
                <div className="text-xl text-center font-serif border-l italic mx-auto mb-2">
                  {teamData.post}
                </div>
              </div>
            ))}
        </div>
      </div>
      <AdsComponent dataAdSlot='8778623707' />
    </div>
  );
}
