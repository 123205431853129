import React from "react";
import { Table, TableBody, TableCell, TableRow, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import NavbarAdmin from "../NavbarAdmin";
import { ReactSession } from 'react-client-session';
import { useDetectAdBlock } from "adblock-detect-react";

const drawerWidth = 250;


export default function DeviceBrandMangement() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  ReactSession.setStoreType("localStorage");

  const sessionData = ReactSession.get("username");

  if(ReactSession.get("username")){

    let role = sessionData.isAdmin;
  
  }else if(sessionData === ''){
    window.open("/admin","_self");
  }else{
    window.open("/admin","_self");
  }
    return (
        <Box sx={{ display: "flex" }}>
          <NavbarAdmin />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />
            <Box>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Sr No</TableCell>
                    <TableCell>Maintainer Name</TableCell>
                    <TableCell>Maintainers UserName</TableCell>
                    <TableCell>Maintainers Email</TableCell>
                    <TableCell>Github Username</TableCell>
                    <TableCell>Sourceforge UserName</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableBody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>LazyDev</TableCell>
                  <TableCell>LazyDev</TableCell>
                  <TableCell>Lazy@ancientrom.xyz</TableCell>
                  <TableCell>LazyDev-ak</TableCell>
                  <TableCell>keneankit01</TableCell>
                  <TableCell>Official</TableCell>
                </TableRow>
              </Table>
            </Box>
          </Box>
        </Box>
      );
}