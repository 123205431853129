import { useDetectAdBlock } from 'adblock-detect-react';
import React from 'react'
import AncientLogo from './../../Assests/Images/ancientv2.png'
function Logo() {

  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  return (
    <div className='grid md:flex h-screen justify-center items-center '>
        <img className='md:w-1/4 ' src={AncientLogo}></img>
        <div className='text-3xl text-center font-semibold md:text-4xl'>Ancient OS <span><br/>Crafted With Love</span></div>
        
    </div>
  )
}

export default Logo