import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import { useDetectAdBlock } from "adblock-detect-react";
import axios from "axios";
import React from "react";
import DownloadDeviceInfo from "./DownloadDeviceInfo";

export default function DownloadDeviceList(props) {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  const [deviceInfo, setDeviceInfo] = React.useState();
  const [brandList, setBrandList] = React.useState([]);
  const [devices, setDevices] = React.useState([]);

  if (props.brandData && brandList.length === 0) {
    setBrandList(props.brandData);
  }
  if (props.devices && devices.length === 0) {
    setDevices(props.devices);
  }

  const handleDeviceInfo = async (event) => {
    await axios
      .get(`https://api.ancientrom.xyz/api/getDeviceInformationById/${event}`)
      .then((response) => {
        let result = response.data.result
       return(
         setDeviceInfo(result)
        //  window.open("/download","_self")
       )
      })
      
  };

  return (
    <div className=" md:flex md:flex-row gap-5">
      <div className="md:w-[25%] h-max grid grid-cols-2 gap-2 md:block text-center mb-4 lg:mb-0">
 
        {brandList?.map((data, index) => (
          // console.log("deviceBrand",data),
          <Accordion sx={{ pointerEvents: "visiblePainted" }}>
            <AccordionSummary>
              <p className="font-serif font-bold text-xl">{data.brandName}</p>
            </AccordionSummary>
            {devices?.map((data1, index) => (
              // console.log("device",data1),
                data1.brandname === data.brandName ? 
              ( data1.display ? 
              <AccordionDetails>
                <Button sx={{width:'100%',margin:'2px',marginBottom:'0px',border:'2px solid grey',color:'black'}} onClick={() => handleDeviceInfo(data1.id)}>
                  {data1.brandname} {data1.devicemodel} ({data1.codename})
                </Button>
              </AccordionDetails> : null)
                : null
            ))}
          </Accordion>
        ))}
      </div>
      {deviceInfo ? (
        <DownloadDeviceInfo deviceInfo={deviceInfo} />
      ) : (
        <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-3">
          {/* {console.log("Hello",props.deviceData)} */}
          {props.deviceData.map((data, index) => (
            <div
              className="h-28 border-2 flex items-center p-2 justify-center mt-2 lg:mt-0 text-lg  shadow-sm rounded-md hover:shadow-lg"
              onClick={() => handleDeviceInfo(data.id)}
            >
              {data.brand} {data.device_model} ({data.device_code_name})
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
