import React from 'react'

export default function AntiAdBlockPage() {
  return (
    <div className='flex h-screen'>

    <div class="m-auto text-xl text-red-800">
        Kindly Turn OFF AdBlocker to visit this website 
    </div>
    </div>
  )
}
