import {
  Accordion,
  AccordionSummary,
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { Link } from "react-router-dom";
import { ReactSession } from "react-client-session";
import { useDetectAdBlock } from "adblock-detect-react";

const drawerWidth = 250;

export default function NavbarAdmin() {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  ReactSession.setStoreType("localStorage");

  const sessionData = ReactSession.get("username");

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [menuData, setMenuData] = useState([]);
  const [displayComp, setDisplayComp] = useState([]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let NavData;
  const fetchData = async () => {
    return await axios
      .get("https://api.ancientrom.xyz/api/adminNav/getMenu")
      .then((response) => response.data)
      .then((response) => {
        NavData = response;
        setMenuData([...NavData]);
      });
  };

  const handleSingOut = () => {
    localStorage.clear();
  };

  useEffect(() => {
    fetchData();
 }, []);

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          AncientOS
        </Typography>
      </Toolbar>
      <Divider />
      {menuData.map((text, index) => {
        if (sessionData.isAdmin && text.isAdmin) {
          return (
            <Accordion
              sx={{ borderBottom: "2px solid #cfcfcf" }}
              name="{text}"
              key={text.id}
            >
              <Link to={text.navOptionLink}>
                <AccordionSummary>{text.navOption}</AccordionSummary>
              </Link>
            </Accordion>
          );
        } else if (sessionData.isMaintainer && text.isMaintainer) {
          return (
            <Accordion
              sx={{ borderBottom: "2px solid #cfcfcf" }}
              name="{text}"
              key={text.id}
            >
             <Link to={text.navOptionLink}>
                <AccordionSummary>{text.navOption}</AccordionSummary>
              </Link>
            </Accordion>
          );
        } else if (sessionData.isApplicant && text.isApplicant) {
          return (
            <Accordion
              sx={{ borderBottom: "2px solid #cfcfcf" }}
              name="{text}"
              key={text.id}
            >
              <Link to={text.navOptionLink}>
                <AccordionSummary>{text.navOption}</AccordionSummary>
              </Link>
            </Accordion>
          );
        }
      })}
      <Accordion
        sx={{ borderBottom: "2px solid #cfcfcf" }}
        name="{text}"
        key={"99"}
        onClick={handleSingOut}
      >
        <Link to={"/admin"}>
          <AccordionSummary>Sign Out</AccordionSummary>
        </Link>
      </Accordion>
    </div>
  );

  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "white",
          color: "Black",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Admin Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
