import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import React from "react";
import Parser from "html-react-parser";
import { ButtonStyle } from "../Admin/Components/Style";
import AdsComponent from "../AdsComponent";
import { useDetectAdBlock } from "adblock-detect-react";
function DownloadDeviceInfo(props) {
  const adBlockDetected = useDetectAdBlock();

  React.useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      window.open("/AntiAdBlockPage","_self")
    }
 }, [adBlockDetected]);
  const [deviceData, setDeviceData] = React.useState();
  if (props.deviceInfo && deviceData == null) {
    setDeviceData(props.deviceInfo);
  }

  return (
    <div>
      <Accordion defaultExpanded={true} sx={{ pointerEvents: "none" }}>
        <AccordionSummary>
          <Typography>
            <p className="font-serif font-bold text-xl">Device Information</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* BASIC SUMMARY */}
          <Accordion
            defaultExpanded={true}
            sx={{
              pointerEvents: "auto",
              boxShadow: "inset 0 0 4px #c7c5c5",
              borderRadius: "16px",
            }}
          >
            <AccordionSummary>
              <div className="grid grid-cols-1">
              <div className="lg:hidden lg:w-[25%]">
                <img
                  className="w-[90%]] mt-[5%] mx-auto"
                  src={deviceData?.device_image_url}
                  alt=""
                />
              </div>
              <Typography>
                <p className="font-serif font-bold text-xl">
                  Basic Information
                </p>
              </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex">
                <div className="w-[75%]">
                  <div>
                    Device Name : {deviceData?.brand_name}{" "}
                    {deviceData?.device_model} ( {deviceData?.device_code_name}{" "}
                    )
                  </div>
                  <div>Maintainer : {deviceData?.maintainer}</div>
                  {deviceData?.comaintainer !== "temp" ? (
                    <div>Co-Maintainer : {deviceData?.comaintainer}</div>
                  ) : null}

                  {deviceData?.coMaintainerUserName ? (
                    <div>
                      Co Maintainer Name : {deviceData?.coMaintainerUserName}{" "}
                    </div>
                  ) : null}
                  <div>
                    Device Maintain Status :{" "}
                    {deviceData?.maintained
                      ? "Maintained"
                      : "Dropped From Support"}
                  </div>
                  <div>
                    Supported Android Versions : {deviceData?.android_version}
                  </div>
                  <div>
                    Supported Rom Version : {deviceData?.rom_build_version}
                  </div>
                </div>
                <div className="hidden lg:block lg:w-[25%]">
                  <img
                    className="w-[80%] h-full mt-[-20%]"
                    src={deviceData?.device_image_url}
                    alt=""
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <AdsComponent dataAdSlot='8778623707' />
          {/* Rom Support Channel */}
          <Accordion
            defaultExpanded={true}
            sx={{
              pointerEvents: "auto",
              boxShadow: "inset 0 0 4px #c7c5c5",
              borderRadius: "16px",
            }}
          >
            <AccordionSummary>
              <Typography>
                <p className="font-serif font-bold text-xl">
                  Rom & Device Support Group Link's
                </p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-[90%] mx-auto">
                <p>
                  Rom Support Channel :
                  <a href={deviceData?.rom_support_channel}>AncientOS Update</a>
                </p>
                <p>
                  {" "}
                  Rom Support Group :{" "}
                  <a href={deviceData?.official_rom_group}>Ancient.id</a>
                </p>
                <p>
                  {" "}
                  Device Support Group :{" "}
                  <a href={deviceData?.device_support_group}>
                    Device Support Group click here to visit
                  </a>{" "}
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
          <AdsComponent dataAdSlot='8778623707' />
          {/* Device Changelog */}
          <Accordion
            defaultExpanded={true}
            sx={{
              pointerEvents: "auto",
              boxShadow: "inset 0 0 4px #c7c5c5",
              borderRadius: "16px",
            }}
          >
            <AccordionSummary>
              <Typography>
                <p className="font-serif font-bold text-xl">Device Changelog</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <div className="w-[90%] mx-auto">

                  {deviceData?.release_changelogs ? (
                    <div>{Parser(deviceData?.release_changelogs)}</div>
                  ) : null}
                </div>
                <div className="font-serif font-bold text-xl">
                  Roms Source Changelog{" "}
                </div>
                <div className="flex items-center justify-center ">
                  <a href="/update">
                    <Button sx={{ ...ButtonStyle }} variant="outlined">
                      <p className="font-serif font-bold text-lg">
                        Source Changelog Available Here
                      </p>
                    </Button>
                  </a>
                </div>

                <div className="font-serif font-bold text-xl">Notes</div>
                <div className="w-[90%] mx-auto">

                  {deviceData?.notes ? (
                    <div>{Parser(deviceData?.notes)}</div>
                  ) : null}

                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Flashing Proccess */}
          <Accordion
            defaultExpanded={true}
            sx={{
              pointerEvents: "auto",
              boxShadow: "inset 0 0 4px #c7c5c5",
              borderRadius: "16px",
            }}
          >
            <AccordionSummary>
              <Typography>
                <p className="font-serif font-bold text-xl">
                  Flashing Proccess
                </p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-[90%] mx-auto">

                {deviceData?.flashing_instruction ? (
                  <div>{Parser(deviceData?.flashing_instruction)}</div>
                ) : null}

              </div>
            </AccordionDetails>
          </Accordion>
          <AdsComponent dataAdSlot='8778623707' />
          {/* Downloads */}
          <Accordion
            defaultExpanded={true}
            sx={{
              pointerEvents: "auto",
              boxShadow: "inset 0 0 4px #c7c5c5",
              borderRadius: "16px",
            }}
          >
            <AccordionSummary>
              <Typography>
                <p className="font-serif font-bold text-xl">
                  Download Builds (Mirros)
                </p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-[90%] mx-auto">
                <div>
                  Supported Rom Version : {deviceData?.rom_build_version}{" "}
                </div>
                <div>
                  Build Types Supported : {deviceData?.rom_build_version_type}{" "}
                </div>
                <div className="flex items-center justify-center ">
                  <a href={deviceData?.download_link}>
                    <Button sx={{ ...ButtonStyle }} variant="outlined">
                      Download latest Builds (mirror: Pling)
                    </Button>
                  </a>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default DownloadDeviceInfo;
